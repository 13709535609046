import React from 'react'
import '../styles/Transparencia.css'

const Sobre = () => {
    return (
        <>
            <div class="center">
                <h1>Portal da Transparência</h1>
                <h2>
                    Acesse nossos Planos:
                </h2>
                <ul className="link-list">
                    <li className="link-item">
                        <a href="https://drive.google.com/file/d/1vY2wSATeNd8TB2AAMNSiEL7fwbHVKFw8/view?usp=drive_link" target="_blank" rel="noopener noreferrer">
                            Plano de ação 2023
                        </a>
                    </li>
                    <li className="link-item">
                        <a href="https://drive.google.com/file/d/1C95ZuQzlQYKZrDDHDTrl2dXHhulqP-l3/view?usp=drive_link" target="_blank" rel="noopener noreferrer">
                            Plano estratégico 2023 - 2025
                        </a>
                    </li>
                </ul>
            </div>

            <div class="txtFinal">
                <p>Contribuia com nossa proposta</p>
                <div className='button_doar_container'>
                    <a href="https://www.paypal.com/donate/?hosted_button_id=9M55EEWXMWWVW">
                        <button className='button_doar'>
                            DOAR
                        </button>
                    </a>
                </div>
            </div>
        </>
    );
};

export default Sobre;
import React from "react"
import '../styles/Home.css'

class Home extends React.Component {
    render() {
        return (
            <>
                <div>
                    <img src='Material Grafico\imagemCapa-JPG.jpg' alt="Imagem de capa" className='presentation' />
                </div>

                <div className='font-bold-custom'>
                    Atuamos em:
                </div>

                <div className='activitys'>
                    <div className='activity'>
                        <img src='Material Grafico\educacao300.jpg' alt="Imagem de educação" className='rounded imageWH border-yellow' /><br />
                        <div>
                            <h3 className='activityTitle'>EDUCAÇÂO</h3>
                            <p className='activityText'>
                                Apoio intensivo e atendimento educacional especializado ao estudante com deficiência intelectual e múltipla
                                incluído na escola comum nas séries iniciais de ensino fundamental.
                            </p>
                        </div>
                    </div>

                    <div className='activity'>
                        <img src='Material Grafico\protecao300.jpg' alt="Imagem de proteção" className='rounded imageWH border-blue' />
                        <div>
                            <h3 className='activityTitle'>PROTEÇÃO</h3>
                            <p className='activityText'>
                                Defesa e garantia de direitos de pessoas com deficiência nas mais diferentes instâncias, visando suas
                                necessidades de desenvolvimento, saúde e bem-estar, e combatendo a violência e a exploração.
                            </p>
                        </div>
                    </div>

                    <div className='activity'>
                        <img src='Material Grafico\saude300.jpg' alt="Imagem de saude" className='rounded imageWH border-green' />
                        <div>
                            <h3 className='activityTitle'>SAÚDE</h3>
                            <p className='activityText'>
                                Acompanhamento a pessoa com deficiência, em todo o seu ciclo de vida, nas mais diversas especialidades, desde
                                a prevenção a reabilitação, com atenção especializada.
                            </p>
                        </div>
                    </div>

                    <div className='activity'>
                        <img src='Material Grafico\assistencia300.jpg' alt="Imagem de assistencia" className='rounded imageWH border-red' />
                        <div>
                            <h3 className='activityTitle'>ASSISTÊNCIA SOCIAL</h3>
                            <p className='activityText'>
                                Alianças estratégicas com vários setores e segmentos sociais para a melhoria da qualidade de vida einclusão da
                                pessoa com deficiência.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='history'>
                    <div className='historyText'>
                        <h1 id='tituloHistoria'>Nossa Historia</h1>
                        A Associação de Pais e Amigos dos Excepcionais de Bueno Brandão, mantenedora da Escola da Fraternidade e Educação
                        Especial, surgiu do esforço de pessoas de vários segmentos da sociedade como o Comandante Wanderley Aparecido do
                        Nascimento, os pais Márcia Antonia Lucas, Suelene Almeida, "Tiana do Messias", Maria Orlanda Bueno e outros,
                        buscando atendimento especializado aos que necessitavam da educação especial e, muitas vezes estavam excluídos de
                        nossa sociedade.
                        Sua criação foi em 27 de outubro de 1990, mas a inauguração e o funcionamento só aconteceram no ano seguinte, com
                        o apoio da Prefeitura Municipal e o prefeito da época Dr. Cleudes Antônio Chíco que cedeu o prédio, equipou a
                        entidade e firmou um convênio com repasses em dinheiro e de funcionário (professores, motorista, especialistas,
                        merenda e outros). Atualmente a APAE de Bueno Brandão recebe o repasse de uma psicóloga (jornada de 8 horas
                        semanais), uma fonoaudióloga (jornada de 12 horas semanais), uma dentista (jornada de 4 horas semanais), uma
                        assistente social (jornada de 4 horas semanais), um repasse de R$ 2.000,00 (dois mil reais mensais), merenda
                        escolar e transporte escolar.
                    </div>
                    <img src="Material Grafico/HistoriaAPAE.jpg" alt="Frente da APAE com alunos" class="historyImage"/>
                </div>

                <div className='button_doar_container'>
                    <p style={{ fontSize: 20, color: 'green', fontWeight: 'bold' }}>Contribua com nossa causa!</p>
                    <a href="https://www.paypal.com/donate/?hosted_button_id=9M55EEWXMWWVW">
                        <button className='button_doar'>
                            DOAR
                        </button>
                    </a>
                </div>
            </>
        );
    }
}

export default Home;